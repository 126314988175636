
.custom-accordion-item {
  background-color: #232323;
  border: 1px solid white;
}

.custom-accordion-button {
  color: white;
  background-color: #232323;
  border: none;
}

.custom-accordion-button::after {
  filter: invert(1); /* Invert the color of the arrow icon */
}

.custom-accordion-body {
  color: white;
  background-color: black;
  border-top: 1px solid white;
  z-index: 2;
}

.accordion-button {
  &:not(.collapsed) {
    background-color: #232323;
    color: white;
  }
}

#faqAccordion {
  z-index:2 !important;
}

.faq_con {
  width:100%;
  display:inline-block;
  position: relative;
  z-index:2;
  //pointer-events: none;
  background:#e7f1ff;
}