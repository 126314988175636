$primary-color: #c19d00;
$white:#fff;
$black:#000;
$light-black:#333;
$red:#f44336;
$poppins: 'Poppins', sans-serif;

.jobInfoD{
	width:100%;
	text-align:center;
	h1{
		text-align:center;
		margin:0px;
		font-size:30px;
		font-weight:600;
		margin-bottom:10px;
		color:#fff;
	}
	p{
     color:#fff;
	 text-align:center;
	 font-size:16px;
	 margin-bottom:50px;
	}
}
.outerNameJob{
	width:600px;
    display: flex;
    justify-content: space-between;
	margin:0 auto;
	@media screen and (max-width:768px) {
		width:100%;
	}
	.inputInfo{
		width:32%;
		float:left;
		label{
			font-weight: 500;
			line-height: 1.2;
			font-size: 16px;
			width: 100%;
			color: #fff;
		}
		select{
			display: block;
			width: 100%;
			height: calc(1.5em + 0.75rem + 2px);
			padding: 0.375rem 0.75rem;
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.5;
			color: #495057;
			background-color: #fff;
			background-clip: padding-box;
			border: 1px solid #ced4da;
			border-radius: 0.25rem;
			transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
		}
	}
}

.cardDataJobInfo{
	width:600px;
	margin:0 auto;
	@media screen and (max-width:768px) {
		width:100%;
	}
	.RowInfoData{
		width: 100%;
		display: flex;
		justify-content: space-between;
		background: #fff 0 0 no-repeat padding-box;
		box-shadow: 0 0 10px #ccc;
		border-radius: 3px;
		opacity: 1;
		box-sizing: border-box;
		margin: 16px 0 0;
		padding:0px;
		.leftInfoCnt{
			width: 33%;
			padding: 10px 20px;
			box-sizing: border-box;
		}
	}
	.loopData{
		position: relative;
		width:100%;
		.leftInfoCntAllow{
			position: absolute;
			right: -108px;
			top: 2px;
			@media screen and (max-width:767px) {
				position: inherit;
				right: 0;
				left: inherit;
				margin-top: 15px;
				text-align: right;
			}
			button{
				text-align: left;
				text-decoration: none;
				letter-spacing: 0;
				opacity: 1;
				font-size: 16px;
				line-height: 19px;
				cursor: pointer;
				border: none;
				background: #c19d00;
				color: #fff;
				padding:11px 10px 10px;
				border-radius: 5px;
			  }
		}
	}
}
.noJobFound{
	text-align:center;
	width:100%;
	display:inline-block;
}