$primary-color: #c19d00;
$white:#fff;
$black:#000;
$light-black:#333;
$red:#f44336;

$poppins: 'Poppins', sans-serif;
.info-hsn{
    width:100%;
    display:inline-block;
    h2{
    font-family:$poppins;
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.18px;
    text-align: left;
    color: #c19d00;
    margin-bottom:21px;
    }
    p{
        font-family:$poppins;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 0.15px;
        text-align: left;
        color: #000000;
        b{
            font-style:italic;
        }
    }
    h3{
        font-size:18px;
        text-decoration:underline;
        margin:0px;
    }
}