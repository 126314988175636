$primary-color: #c19d00;
$white:#fff;
$black:#000;
$light-black:#333;
$red:#f44336;

$poppins: 'Poppins', sans-serif;
.footer-main{
	width:100%;
	display:inline-block;
	position: relative;
	z-index:1;
	pointer-events: none;
	.top-header{
		width:100%;
		display:inline-block;
		position: relative;
		z-index:1;
		pointer-events: none;
		background: #232323;
		padding:65px 0px;
		.footer-logo{
			width:100%;
			display:inline-block;
			img{
				max-width:130px;
                margin-bottom:15px;
			}
			p{
				color:#fff;
				font-size:14px;
				margin: 0.2px 0 0;
				width:60%;
			}
		}
		h6{
			color:#fff;
			font-weight:600;
		}
		ul{
			list-style:none;
			margin:0px;
            padding:0px;
			li{
				a{
				  object-fit: contain;
				  font-family:$poppins;
				  font-size: 14px;
				  font-weight: normal;
				  font-stretch: normal;
				  font-style: normal;
				  line-height: 2.57;
				  letter-spacing: normal;
				  text-align: left;
				  color: #ffffff;
                  text-decoration:none;
				  pointer-events: auto;
					&:hover{
						color:#c19d00;
                        text-decoration:underline;
					}
				}
			}
		}
	}
	.bottom-header{
		width:100%;
		display:inline-block;
		background:#1c1c1c;
		padding:15px 0px 12px;
		.privacy{
		  width:100%;
		  display:inline-block;
		  object-fit: contain;
		  font-family:$poppins;
		  font-size: 15px;
		  font-weight: normal;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: normal;
		  letter-spacing: normal;
		  text-align: left;
		  color:#fff;
		  margin:0px 0px 0px;
			a{
				color: #c19d00;
                text-decoration:none;
				pointer-events: auto;
				&:hover{
						color:#fff;
                        text-decoration:none;
					}
			}
		}
		.social-icon{
			width:100%;
			display:inline-block;
			text-align:right;
			img{
				margin-left:16px;
				width:26px;
			}
		}
	}
}


.heading {
	font-size: 15px;
	font-weight: bold;
	margin-bottom: 20px;
}

.icon-media {
	display: flex;
	align-items: center;
}

.icon-media a {
	display: inline-block;
	margin-right: 16px;
	text-decoration: none;
	img{
		width:20px;
		height: 20px
	}
}
.scrollTopInfo{
	position: fixed;
    font-size: 20px;
    bottom: 40px;
    right: 40px;
    background-color:#c19d00;
    color: rgb(255, 255, 255);
    text-align: center;
	border:none;
	box-shadow:0px 0px 10px #ccc;
	width:50px;
	height:50px;
	border-radius:100%;;
	img{
		width:100%;
		height:100%;
	}
}
.startupIndia{
	width:100%;
	display:inline-block;
	margin-top:15px;
	img{
		max-width:200px;
	}
}
.startupIndiaInfo{
	width: 100%;
    display: inline-block;
	margin-top:10px;
    // padding: 60px 40px;
    // position: relative;
    // background-color: rgba(255, 255, 255, .1);
    // border-top: 8px solid $primary-color;
}