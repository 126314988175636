$primary-color: #c19d00;
$secondary-color: #fff;
$white:#fff;
$black:#000;
$light-black:#333;
$red:#f44336;
$poppins: 'Poppins', sans-serif;
.justify{
	align-items: center;
    justify-content: center;
}
.imglogo.rocket{
	img{
		width:100%;
	}
}
.imglogo {
	img{
		max-width:100%;
		border-radius:6px;
	}
}
.trusted-data{
	width:100%;
	display:inline-block;
	padding-top:50px;
	position: relative;
    z-index: 1;
    pointer-events: none;;
	.content{
		width:100%;
		display:inline-block;
		padding:40px 0px;
		p{
		  object-fit: contain;
		  font-family: $poppins;
		  font-size: 50px;
		  font-weight: normal;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: normal;
		  letter-spacing: normal;
		  text-align: left;
		  color: #ffffff;
		  margin:0px;
		  line-height:52px;
		  @media screen and (max-width:768px) {
			font-size:30px;
			line-height:40px;
		  }
		}
	}
	.br-right{
		width:100%;
		display:inline-block;
		img{
			max-width:100%;
		}
	}
}
.headingDataInfo{
	width:100%;
	display:inline-block;
	text-align:center;
	h2{
		color:#fff;
	}
	p{
		color:#fff;
		font-size:16px;
	}
	.childHead{
		padding: 10px 22px 8px;
		object-fit: contain;
		border-radius: 15px;
		background-color: #e6f2ff;
		font-size: 16px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: 0.16px;
		text-align: left;
		color: #c19d00;
		font-family:$poppins;
		display:inline-block;
		margin-bottom:15px;
		@media screen and (max-width:768px) {
			text-align:center;
		}
	}
	h1{
		font-size: 30px;
		font-weight: 700;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: 0.3px;
		text-align:center;
		color: #444;
		font-family:$poppins;
		margin-bottom:33px;
		b{
			color: #c19d00;
			font-weight:700;
		}
	}
}
.absolute{
	width:100%;
	display:inline-block;
	object-fit: contain;
	background-color: #ffffff;
	margin-bottom:4rem;
	.absolute-content{
		width:100%;
		display:inline-block;
		padding:30px;
		box-sizing: border-box;
		@media only screen and (max-width:767px) {
			padding:0px;
		}
		h6{
		  object-fit: contain;
		  font-family: $poppins;
		  font-size: 18px;
		  font-weight: 600;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: 1.33;
		  letter-spacing: 0.18px;
		  text-align: left;
			color: #c19d00;
			text-transform:uppercase;
		}
		h1{
			margin:32px 0px 20px 0px;
			object-fit: contain;
			font-family: $poppins;
			font-size: 36px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: #444;
		}
		p{
			 object-fit: contain;
			 font-family: $poppins;
			 font-size: 16px;
			 font-weight: normal;
			 font-stretch: normal;
			 font-style: normal;
			 line-height: 1.5;
			 letter-spacing: normal;
			 text-align: left;
			 color: #444;
		}
		.btn-primary{
			padding: 9px 19px 8px;
			object-fit: contain;
			border-radius: 15px;
			border: solid 1px #c19d00;
			background-color: #fff;
			font-size: 16px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: 0.16px;
			text-align: left;
			color: #c19d00;
			&:hover{
		     background: #c19d00;
			border-color: #c19d00;
			color:#fff;
			}
		}
	}
}

.show_my_content{
	margin-bottom:4rem;
	position: relative !important;
	z-index: 1 !important;
	pointer-events: none !important;
	background: none !important;
}
.featurette{
	margin-bottom:4rem;
	position: relative !important;
    z-index: 1 !important;
    pointer-events: none !important;
    background: none !important;
	img{
		max-width:100%;
	}
	h6{
		  object-fit: contain;
		  font-family: $poppins;
		  font-size: 18px;
		  font-weight: 600;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: 1.33;
		  letter-spacing: 0.18px;
		  text-align: left;
			color: #fff;
			text-transform:uppercase;
		}
		h1{
			margin:10px 0px 30px;
			object-fit: contain;
		  font-family:$poppins;
		  font-size: 50px;
		  font-weight: normal;
		  font-stretch: condensed;
		  font-style: normal;
		  line-height: normal;
		  letter-spacing: normal;
		  text-align: left;
		  color: #fff;
		}
		p{
			 object-fit: contain;
			 font-family: $poppins;
			  font-size: 18px;
			  font-weight: 400;
			  font-stretch: normal;
			  font-style: normal;
			  line-height: 1.33;
			  letter-spacing: 0.18px;
			  text-align: left;
			  color: #fff;
			  margin:0px;
		}
}
.secure-data{
	width:100%;
	display:inline-block;
	background:#cce5ff;
	border-radius:30px;
	overflow:hidden;
	margin-bottom:4rem;
	.scrty{
		img{
			max-width:100%;
		}
	}
	.securty-data{
		width:100%;
		display:inline-block;
		text-align:center;
		box-sizing: border-box;
		//background-image: url(../images/setting.webp);
		background-repeat: no-repeat;
		background-size: 83px;
		background-position: right 98%;
		height: 100%;
		h3{
		  object-fit: contain;
		  font-family:$poppins;
		  font-size: 50px;
		  font-weight: normal;
		  font-stretch: condensed;
		  font-style: normal;
		  line-height: normal;
		  letter-spacing: normal;
		  text-align: center;
		  color: $primary-color;
		  margin:30px 0px 0px;
		}
		h1{
		  object-fit: contain;
		  font-family:$poppins;
		  font-size: 50px;
		  font-weight:bold;
		  font-stretch: condensed;
		  font-style: normal;
		  line-height: normal;
		  letter-spacing: normal;
		  text-align: center;
		 color: $primary-color;
		}
		p{
			object-fit: contain;
		  font-family: $poppins;
		  font-size: 25px;
		  font-weight: normal;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: normal;
		  letter-spacing: normal;
		  text-align: center;
		  color: $primary-color;
			margin:0px 0px 0px;
		}
	}
}
.company-url{
	width:100%;
	display:inline-block;
	margin-bottom:4rem;
	h5{
	font-size: 43px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #c19d00;
    margin-bottom: 30px;
	font-family:$poppins;
	}
	a{
		text-decoration:none;
		display:inline-block;
		width:100%;
		.inner-box{
			padding: 30px 10px;
			width: 100%;
			box-sizing: border-box;
			margin-bottom: 25px;
			color: #fff;
			text-align: center;
			border: solid 1px rgba(42,58,141,0.18);
			background-color:$primary-color;
			border-radius:20px;
			transition:box-shadow 0.5s;
			min-height: 342px;
			&:hover{
				box-shadow: 0 0 11px  #000;
				background:#c19d00;
			}
			h1{
				color:$white;
				font-size:20px;
				margin:10px 0px;
				text-decoration:none;
			}
			p{
				color:$white;
				font-size:16px;
				margin:0px;
			}
		}
	}
}
.company-url.clientdata{
	background:#fff;
    padding: 107px 0px 107px;
    margin: 0;
    float: left;
    width: 100%;
	h5{
		margin:0px;
	}
	.clientOuter{
		width: 100%;
		display: inline-flex;
		align-items: center;
		flex-wrap: wrap;
		@media screen and (max-width:767px) {
			flex-wrap: wrap;
		}
		.imglogo{
			padding: 43px;
			margin: 0px 10px;
			min-height: 130px;
			display: flex;
			align-items: center;
			justify-content: space-around;
			margin-bottom:20px;
			width:23%;
			height:150px;
			@media screen and (max-width:767px) {
				width: 100%;
				box-sizing: border-box;
				min-width: auto;
				margin-bottom: 20px;
				height:100%;
			}
			.imgsmall{
				margin: 30px 24px;
			}
			.imgbig{
				max-width:230px !important;
			}
			.imgmedium{
				max-width:195px !important;
			}
		}
	}
}
.testimonialsInfo{
	width:100%;
	display:inline-block;
	background-image: url('../testBg.png');
	background-repeat:no-repeat;
	min-height:457px;
	position: relative;
    z-index: 1;
    pointer-events: none;
	@media only screen and (max-width:767px) {
		padding-bottom:50px;
	}
	&::before{
	content:"";
	background-color:#00000087;
	position: absolute;
	top:0;
	left:0;
	bottom:0;
	margin:0 auto;
	height:100%;
	width:100%;
	}
    .contentTst{
		width:100%;
		display:inline-block;
		margin-top:6rem;
		@media only screen and (max-width:767px) {
			margin-top:3rem;
		}
		h5{
			font-size: 48px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.25;
			letter-spacing: normal;
			text-align: left;
			color: #fff;
			font-family:$poppins;
			@media only screen and (max-width:767px) {
				font-size:25px;
				margin-bottom:20px;
			}
		}
		p{
			font-size: 24px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.5;
			letter-spacing: normal;
			text-align: left;
			color: #fff;
			font-family:$poppins;
			margin-top:40px;
			@media only screen and (max-width:767px) {
				font-size:18px;
				margin:0px;
			}
		}
	}
	.sliderTest{
		width:100%;
		padding: 31px 50px 31px 32px;
		object-fit: contain;
		border-radius: 10px;
		box-shadow: 0px 2px 9.8px 0.2px rgba(0, 0, 0, 0.1);
		border: solid 1px #000;
		background-color: #fff;
		box-sizing: border-box;
		margin-top:46px;
		position: relative;
		z-index:9;
	}
}
@media only screen and (max-width:767px) {
	.outer-login-part{
		&:after{
			display:none;
		}
		&:before{
			display:none;
		}
	}
	.profile-outer-field{
		.auto-width{
			padding: 15px;
			width: 100% !important;
			box-sizing: border-box;
			margin:0px;
		}
		h1{
			font-size: 24px;
		}
		}
	   .profile-company{
		  .logo{
				img{
					max-width: 145px;
				}
		   }
	   }
	.absolute{
		img{
			max-width:100%;
		}
	}
	.trusted-data{
		.br-right{
			img{
				position:inherit;
			}
		}
		}
	.footer-logo{
		margin-bottom:20px;
	}
	.carousel{
		text-align: center !important;
		.carousel-caption {
		h1{
			font-size:40px;
			margin: 14px 0px 0px;
			text-align: center;
		}
		p{
			margin:14px 0px;
			text-align:center;
		}
	}
	}
	.header-top{
		.btn-primary{
			margin-bottom:10px;
		}
	} 
	
}
.solution{
	width:100%;
	display:inline-block;
	padding:60px 0px 50px;
	position: relative;
    z-index:1;
	pointer-events: none;
	@media only screen and (max-width:767px) {
		padding-top:0px;
	}
	.innerBox{
		width:100%;
		padding: 30px 37px 37px 41px;
		object-fit: contain;
		border-radius: 10px;
		box-shadow: 0px 2px 9.8px 0.2px rgba(0, 0, 0, 0.1);
		background-color: #fff;
		text-align:center;
		margin-bottom:25px;
		min-height: 383px;
		position: relative;
		z-index:1;
		pointer-events: none;
		img{
			width:46px;
		}
		.whiteHover{
			display:none;
		}
		h4{
			object-fit: contain;
			font-size: 24px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align:center;
			color: #fff;
			margin:26px 0px;
			font-family:$poppins;
		}
		p{
			object-fit: contain;
			font-family:$poppins;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.5;
			letter-spacing: normal;
			text-align: center;
			color: #444;
		}
		&:hover{
			background:#c19d00;
			svg{
				color: #fff;
			}
			h4{
				color:#fff;
			}
			p{
				color:#fff;
			}
			img{
				display:none;
			}
			.whiteHover{
				display:inline !important;
			}
		}
	}
	.info-sme{
		width:100%;
		display:inline-block;
		position: relative;
		z-index:1;
		pointer-events: none;
		h2{
			object-fit: contain;
			font-family:$poppins;
			font-size: 30px;
			font-weight: normal;
			font-stretch: condensed;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align:center;
			color: #c19d00;
			margin:60px 0px 40px;
		}
	}
}
.portfolio{
	width:100%;
	display:inline-block;
	padding:40px 0px 0;
	position: relative;
    z-index:1;
	pointer-events: none;
	.portFolioOuter{
		width:100%;
		padding:20px 0px 0px;
		display:inline-block;
		float: left;
		position: relative;
		z-index:1;
		pointer-events: none;
		.sectionPort{
			width:25%;
			float:left;
			position: relative;
			z-index:1;
			pointer-events: none;
			img{
				max-width:100%;
				width:100%;
				height:336px;
			}
		}
	}
}
.unique-outer{
	width:100%;
	display:inline-block;
	padding:0px;
	position: relative;
    z-index:1;
	pointer-events: none;
	h1{
	object-fit: contain;
    font-family:$poppins;
    font-size: 50px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #c19d00;
	}
	.solutution-data{
		padding-bottom:15px;
		border-bottom:1px solid #868e96;
		width:100%;
		display:inline-block;
		margin-bottom:25px;
		position: relative;
		z-index:1;
		pointer-events: none;
		h4{
			object-fit: contain;
			font-size: 25px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: #c19d00;
		}
		p{
			object-fit: contain;
			font-size: 15px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.47;
			letter-spacing: normal;
			text-align: left;
			color:$primary-color;
		}
		img{
			max-width:100%;
		}
	}
}
.absolute{
	img{
		max-width:100%;
		height:100%;
	}
}
.footerSubs{
    padding: 40px 0px;
	width:100%;
	display:inline-block;
	position: relative;
    z-index:1;
	pointer-events: none;
	background:#f7f7f7;
	.flexInfo{
		display:flex;
		align-items:center;
		justify-content:space-between;
		position: relative;
		z-index:1;
		pointer-events: none;
		@media screen and (max-width:768px){
			flex-wrap:wrap;
		}
	}
	.bookingInfo{
		display: inline-block;
		vertical-align: middle;
		transform: perspective(1px) translateZ(0);
		box-shadow: 0 0 1px rgba(0, 0, 0, 0);
		position: relative;
		transition-property: color;
		transition-duration: 0.3s;
		overflow: hidden;
		border: 1px solid #c19d00;
		background-color: #c19d00;
		display: inline-block;
		color: #ffffff;
		text-decoration: none;
		padding: 0.75rem 2.5rem;
		border-radius: 55px;
		position: relative;
		z-index:1;
		pointer-events: none;
	}
	h3{
		font-size: 42px;
		font-weight: 500;
		color: #000;
		line-height: 1.2;
		@media screen and (max-width:768px){
			font-size:24px;
		}

	}
}

.service-left-side:hover,
.service-left-side.active {
    border: 1px solid #c19d00;
    outline-color: #c19d00;
    outline-style: double;
    outline-width: 1px;
    outline-offset: 7px;
	border-radius:6px;
    // background: url(../images/ce4-11-hover.webp) right center no-repeat;
}

.service-right-side:hover,
.service-right-side.active {
    border: 1px solid #c19d00;
    outline-color: #c19d00;
    outline-style: double;
    outline-width: 1px;
    outline-offset: 7px;
	border-radius:6px;
    // background: url(../images/ce4-12-hover.webp) left center no-repeat;
}

.service-left-side {
    display: table;
    position: relative;
    width: 92%;
    float: left;
    padding: 40px 160px 40px 40px;
    background-color: #fff;
    border: 1px solid #ddd;
    outline-color: #ddd;
    outline-style: double;
    outline-width: 1px;
    outline-offset: 7px;
    // background: url(../images/ce4-11.webp) right center no-repeat;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.margin-bottom {
    margin-bottom: 0px;
}

.service-left-side:hover .circle,
.service-left-side.active .circle {
    color: #fff;
    background-color: #c19d00;
    border: 6px solid #c19d00;
}

.service-right-side:hover .circle,
.service-right-side.active .circle {
    color: #fff;
    background-color: #c19d00;
    border: 6px solid #c19d00;
}

.service-left-side .circle {
    position: absolute;
    width: 110px;
    height: 110px;
    float: left;
    z-index: 2;
    right: -50px;
    font-size: 18px;
    color: #101010;
    line-height: 97px;
    text-align: center;
    border-radius: 100%;
    background-color: #fff;
    border: 6px solid #f5f5f5;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.text-box {
    width: 100%;
    float: left;
}

.service-left-side:hover .title,
.service-left-side.active .title {
    color: #c19d00;
}

.service-right-side:hover .title,
.service-right-side.active .title {
    color: #c19d00;
}

.title {
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}


/* right side */

.service-right-side {
    display: table;
    position: relative;
    width: 92%;
    float: right;
    padding: 40px 40px 40px 160px;
    background-color: #fff;
    border: 1px solid #ddd;
    outline-color: #ddd;
    outline-style: double;
    outline-width: 1px;
    outline-offset: 7px;
    // background: url(../images/ce4-12.webp) left center no-repeat;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    margin-bottom: 50px;
}

.service-right-side .circle {
    position: absolute;
    width: 110px;
    height: 110px;
    float: left;
    z-index: 2;
    left: -50px;
    font-size: 18px;
    color: #101010;
    line-height: 97px;
    text-align: center;
    border-radius: 100%;
    background-color: #fff;
    border: 6px solid #f5f5f5;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.text-box {
    width: 100%;
    float: left;
}

.service-right-side:hover {
    // background: url(../images/ce4-12-hover.webp) left center no-repeat;
}

.service-right-side:hover .circle {
    color: #fff;
    background-color: #c19d00;
    border: 6px solid #c19d00;
}

.circle i {
    font-size: 50px;
    width: 80px;
    height: 80px;
    line-height: 100px;
    color: #f5f5f5;
}

@media (max-width: 991px) {
    .service-left-side,
    .service-right-side {
        margin-bottom: 35px;
    }
}

@media (max-width: 479px) {
    .service-left-side .circle,
    .service-right-side .circle {
        position: relative;
        float: none;
        right: 0px;
        left: 0px;
        text-align: center;
        margin: 0 auto 15px auto;
    }
    .service-left-side,
    .service-right-side {
        background-image: none;
        padding: 15px;
        width: 96%;
        float: none;
        text-align: center;
        margin: 0 auto 35px auto;
    }
    .service-left-side.active,
    .service-left-side:hover,
    .service-right-side:hover {
        background-image: none;
    }
}
.ourServices{
	width:100%;
	display:inline-block;
	background-color: none !important;
	padding:80px 0px;
	position: relative;
    z-index: 1;
    pointer-events: none;
	@media screen and (max-width:767px) {
		.col-md-4{
			margin:8px 0px;
		}
	}
	
	h5{
		padding: 0 0 20px;
		text-transform: uppercase;
		font-weight: 600;
		text-align:center;
		font-size:24px;
		margin:0px;
		color:#fff;
	}
	p{
		color: #fff !important;
		font-size:14px;
		text-align:center;
	}
	.feature-box {
		padding: 0 0 20px 0;
		position: relative;
		overflow: hidden;
		-webkit-transition: all 0.5s ease-in-out;
		-moz-transition: all 0.5s ease-in-out;
		-ms-transition: all 0.5s ease-in-out;
		-o-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
		padding-bottom: 3rem !important;
		text-align:center;
		position: relative;
    z-index: 1;
    pointer-events: none;
		.feature-box-icon {
			font-size: 50px;
			padding: 20px 0;
			line-height: 24px;
		}
		.feature-box-title {
			font-size: 20px;
			font-weight: 500;
			line-height: 26px;
			margin-bottom: 10px;
			color:#fff;
		}
	}
	.bg-grad {
		background-color:#c19d00;
		h3, p{
			color: #fff !important;;
		}
	}
}
.outerTrustedPartner{
	width:100%;
	display:inline-block;
	box-shadow: 0 15px 20px 0 rgba(0,0,0,.1);
    transition: background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s;
    padding:50px 40px 50px 40px;
	margin-bottom:70px;
	position: relative;
    z-index:1;
	pointer-events: none;
	&.borderTop{
		border:4px solid #c19d00;
		box-sizing: border-box;
	}
	@media only screen and (max-width:767px) {
		padding:25px;
		margin:0px 0px 40px;
	}
	h2{
		color: #fff;
		text-align: center;
		font-size: 30px;
		margin:0px;
		@media only screen and (max-width:767px) {
			font-size:28px;
		}
	}
	p{
		font-size:18px;
		color:#fff;
		text-align:center;
		padding-left: 35px;
		padding-right: 35px;
	}
	&.box-shadow{
		box-shadow:none;
        margin: 0;
		padding:20px;
	}
	.contentInfoDataStay{
		width:100%;
		display:flex;
		align-items: center;
        justify-content: space-between;
		pointer-events: none;
		@media only screen and (max-width:767px) {
			display:inline-block;
		}
		.contentsty{
			@media only screen and (max-width:767px) {
				text-align:center;
			}
			h1{
				color:#fff;
				@media only screen and (max-width:767px) {
					font-size:24px;
				}
			}
			p{
				font-size:16px;
				margin:0px;
				text-align:left;
				color:#fff;
				@media only screen and (max-width:767px) {
					text-align:center;
				}
			}
		}
		.bookingInfo {
			display: inline-block;
			vertical-align: middle;
			transform: perspective(1px) translateZ(0);
			box-shadow: 0 0 1px rgba(0, 0, 0, 0);
			position: relative;
			transition-property: color;
			transition-duration: 0.3s;
			overflow: hidden;
			border: 1px solid #c19d00;
			background-color: #c19d00;
			display: inline-block;
			color: #ffffff;
			text-decoration: none;
			padding: 16px 10px;
			border-radius: 55px;
			min-width: 207px;
			font-size: 18px;
			pointer-events:auto;
			@media only screen and (max-width:767px) {
				width:100%;
				margin-top:20px;
			}
		}
	}
	.wglCanvasOuter {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100% !important;
		height: 100% !important;
		opacity: 1;
		z-index: -1;
		canvas {
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		  }
	  }
}
.technologySection{
	width:100%;
	display:flex;
	align-items:center;
	justify-content:space-between;
	flex-wrap: wrap;
	.cardNewSectionInfo{
		box-shadow: 2px 2px 2px 0 rgba(0,0,0,.04);
		transition: background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s;
		margin:20px 0px 0px;
		position: relative;
		z-index:1;
		pointer-events: none;
		@media only screen and (max-width:767px) {
			width:100%;
			box-sizing: border-box;
			min-height:auto;
		}
		img{
			max-width: 100%;
			max-height: 260px;
			object-fit: fill;
			width:100%;
		}
		h3{
			font-size:20px;
			margin:20px 0px 10px;
			color:#fff;
		}
		p{
			margin:0px;
			color:#fff;
		}
	}
}
.counterInfo{
	width:100%;
	display:inline-block;
	background-color: $primary-color;
	padding: 5em 0em 5em 0em;
	@media only screen and (max-width:767px) {
		padding:40px 0px;
	}
	.conterInfoOuterData{
		width:100%;
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
		.contendata{
			width:25%;
			text-align:center;
			@media only screen and (max-width:767px) {
				width:100%;
				box-sizing: border-box;
				padding:20px 0px;
			}
			h4{
				color: #FFF;
				margin:0px 0px 15px;
				font-size:40px;
			}
			p{
				color:#fff;
				margin:0px;
			}
		}
	}
}

.process {
	$bem-block: &;
  
	width: 100%;
	text-align: center;
	position: relative;
    z-index: 1;
	padding:0px;
    pointer-events: none;
	margin-top:30px;
  
	&__item {
	  user-select: none;
	  text-align: center;
	  position: relative;
	  padding: 15px 35px;
	  transition: 0.4s ease-in-out;
  
	  &:hover {
		background: darken($secondary-color, 5%);
	  }
  
	  &:hover #{$bem-block}__number {
		transform: translateY(5px);
		color: $primary-color;
	  }
	}
  
	&__number {
	  font-size: 90px;
	  -webkit-text-stroke: 1px $primary-color;
	  display: block;
	  color: transparent;
	  font-weight: 700;
	  transition: 0.4s ease-in-out;
	}
  
	&__title {
	  display: block;
	  font-weight: 700;
	  letter-spacing: 1.5px;
	  font-size:22px;
	  color: #fff;
	  text-transform: uppercase;
	  margin-top: 30px;
	}
  
	&__subtitle {
	  display: block;
	  font-size: 20px;
	  color: darken($secondary-color, 45%);
	  margin-top: 30px;
	  line-height: 1.5;
	}
  }
  
  @media (min-width: 768px) {
	.process {
	  display: inline-block;
	  border:1px solid #ccc;
  
	  &__item {
		width: 49%;
		display: inline-block;
	  }
	}
  }
  
  @media (min-width: 1200px) {
	.process {
	  display: flex;
	  flex-direction: row;
	  justify-content: space-between;
  
	  &__item {
		width: 100%;
  
		&:not(:last-of-type) {
		  &::after {
			content: "";
			width: 1px;
			height: 75%;
			background: darken($secondary-color, 45%);
			position: absolute;
			right: 0;
			top: 50%;
			opacity: 0.2;
			transform: translateY(-50%);
		  }
		}
	  }
	}
  }
  .technologySection.justify-content_space-around{
	justify-content:space-around;
	@media only screen and (max-width:767px) {
		text-align:center;
	}
  }
  .ourApprochOuter{
	width:100%;
	display:inline-block;
	text-align:center;
	position: relative;
	height:100%;
	z-index:1;
	pointer-events: none;
	margin-top:40px;
	.lineData{
          position:absolute;
		  left:0;
		  right:0px;
		  bottom:0;
		  height:100%;
		  width:10px;
		  background-color: #c19d00;
		  margin: 0 auto;
          top: -24px;
	}
	.centerDataCard{
		max-width: 400px;
		margin:0 auto;
		.centerCardDataNew{
			width:100%;
			display:inline-block;
			text-align:center;
			position: relative;
			z-index:99;
			.cntHeading{
				height: 100px;
				width: 100px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				border: 1px solid #c19d00;
				margin: 0 auto;
				background: #fff;
				font-size: 30px;
				font-weight: 600;
			}
			.ceardCntnt {
				background: #fff;
				position: relative;
				padding: 20px;
				margin:50px 0px;
				font-size: 24px;
				border: 2px solid red;
			}
		}
	}
  }
  .zeroZeodata{
	width:250px;
	height:250px;
	border:4px solid #ccc;
	border-radius:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	padding:30px;
	color:#fff;
	text-align:center;
	font-size:20px;
	font-weight:600;
  }
  .circleZeroData{
	width:100%;
	display:flex;
	align-items:center;
	justify-content:space-between;
	margin-top:100px;
	@media screen and (max-width:767px) {
		margin-top:30px;
		flex-wrap: wrap;
	}
	.CenterCircleInfo{
		width:350px;
		height:350px;
		border:8px solid #c19d00;
		border-radius:100%;
		display:flex;
		align-items:center;
		justify-content:center;
		padding:30px;
		color:#fff;
		text-align:center;
		font-size:40px;
		font-weight:600;
		min-width: 350px;
		margin: 0px 20px;
		@media screen and (max-width:767px) {
			width: 200px;
			height: 200px;
			min-width: 200px;
			margin: 0 auto;
			margin-bottom: 29px;
		}
	}
	.infodataCnt{
		width:100%;
		display:inline-block;
		.cardNewSectionInfo{
			margin-bottom:50px;
		}
	}
  }
  :root {
	--background-color: #111;
	--font: sans-serif;
  }
