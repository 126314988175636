$primary-color: #c19d00;
$white: #fff;
$black: #000;
$light-black: #333;
$red: #f44336;

$poppins: 'Poppins', sans-serif;
.owlDataclient {
  position: relative;
  z-index: 9;

  //.owl-nav,
  .owl-dot {
    display: none !important;
  }


  .owl-carousel {
    margin: 0;
    background: none;

    .carousel-indicators li {
      width: 10px;
      height: 10px;
      border-radius: 100%;
    }

    .carousel-caption {
      position: inherit;
      padding: 0;
    }

    .owl-item .item {
      padding: 0 1%;
    }

    .owl-nav button.owl-prev ,
    .owl-nav button.owl-next {
      pointer-events: auto;
      color: $white;
      top: -140px;
      background: #414b4a;

      span{
        padding: 1rem;
      }
    }

  }

  @media only screen and (max-width: 767px) {
    .owl-carousel {
      text-align: center !important;

      .carousel-caption {
        h1 {
          font-size: 40px;
          margin: 14px 0px 0px;
          text-align: center;
        }

        p {
          margin: 14px 0px;
          text-align: center;
        }
      }
    }
  }

  .cardSectionInfo {
    width: 100%;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .04);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 20px 0 0;
    padding: 20px;
    min-height: 410px;
    box-sizing: border-box;
    position: relative;
    pointer-events: none;
    border: 1px solid #ccc;
    z-index: 9;

    @media only screen and (max-width: 767px) {
      width: 100%;
      box-sizing: border-box;
      min-height: auto;
    }

    img {
      max-width: 100%;
      max-height: 260px;
      object-fit: fill;
      width: 100%;
    }

    h3 {
      font-size: 20px;
      margin: 20px 0px 10px;
      color: #fff;
      text-align: center;
    }

    p {
      margin: 0px;
      color: #fff;
      text-align: center;
      font-size: 16px;
    }
  }

  a {
    text-decoration: none;
  }
}