.tearmsPrvcy{
	font-size: 15px;
    margin: 0 auto;
    padding: 12px 10px;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    z-index: 9999;
    display: none;
    left: 0px;
    font-weight: 300;
    box-shadow: 0 -1px 10px 0 #ccc;
	background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    font-family: inherit;
    bottom: 0px;
    position: fixed;
    display: block;
    opacity: 1;
}
#cookie-law-info-bar span {
    vertical-align: middle;
}
.cli_settings_button{
	margin: 5px 20px;
    color: #c19d00;
    font-weight: 500;
	cursor: pointer;
}
.medium.cli_action_button{
	margin: 5px;
    background-color: #000;
    color: #fff;
    padding: 7px 15px;
    text-decoration: none;
	display:inline-block;
	cursor: pointer;
}