@media only screen and (max-width: 1280px) {
	.outer-login-part{
		.innerLoginCenter{
		  width:90%;
		}
	}
}
@media only screen and (max-width:992px) {
	.outer-login-part{
		height: initial;
		.inner-login-center{
		    width: 100%;
			position: inherit;
			top: initial;
			left: initial;
			transform: inherit;
			.login-outer{
				.left-conte-data{
					padding:30px 0px 0px;
					text-align:center;
					h1{
						margin:0px 0px 10px;
					}
					h3{
					font-size:24px;	
                    margin:15px 0px 10px;
					}
					p{
						font-size:14px;
					}
				}
			}
		}
		.copy-right {
			position: initial;
			bottom: initial;
			text-align: center;
			width: 100%;
			padding:20px 0px;
		}
		.account-outer{
			margin:15px 0px 0px !important;
		.leftaccount, .rightaccount{
			width:100%;
			text-align:center;
			float:left;
		}
		}
	}
}
@media only screen and (max-width:767px) {
	.outer-login-part{
		&:after{
			display:none;
		}
		&:before{
			display:none;
		}
	}
	.profile-outer-field{
		.auto-width{
			padding: 15px;
			width: 100% !important;
			box-sizing: border-box;
			margin:0px;
		}
		h1{
			font-size: 24px;
		}
		}
	   .profile-company{
		  .logo{
				img{
					max-width: 145px;
				}
		   }
	   }
	.absolute{
		img{
			max-width:100%;
		}
	}
	.trusted-data{
		.br-right{
			img{
				position:inherit;
			}
		}
		}
	.footer-logo{
		margin-bottom:20px;
	}
	.carousel{
		text-align: center !important;
		.carousel-caption {
		h1{
			font-size:40px;
			margin: 14px 0px 0px;
			text-align: center;
		}
		p{
			margin:14px 0px;
			text-align:center;
		}
	}
	}
	.header-top{
		.btn-primary{
			margin-bottom:10px;
		}
	} 
	
}