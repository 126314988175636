@import "../src/sass/variable.scss";
@import "../src/sass/screens.scss";
*{
	margin:0px;;
	padding:0px;
}

html, body{
	width:100%;
	height:100%;
}
body{
	font-family:$poppins;
	font-size:14px !important;
}
.main{
    width:100%;
    position: relative;
    z-index: 99;
}
a{
	pointer-events: auto !important;
}
.animationData{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100% !important;
    height: 100% !important;
    opacity: 1;
    canvas {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}