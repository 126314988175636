$primary-color: #c19d00;
$white:#fff;
$black:#000;
$light-black:#333;
$red:#f44336;

$poppins: 'Poppins', sans-serif;
.banner-info{
    width:100%;
    display:inline-block;
    margin-top:4rem;
    background-color:$primary-color;
    margin-bottom:100px;
    padding:15px 0px;
    background: linear-gradient(90deg, #020024 0%, #c19d00 55%, #00d4ff 100%);
    .content{
        display:inline-block;
        width:100%;
        padding: 16px 70px 10px;
        @media screen and (max-width:767px){
          padding: 16px 0px 10px !important;
        }
        h1{
            object-fit: contain;
            font-family: $poppins;
            font-size: 50px;
            font-weight: bold;
            font-stretch: condensed;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #ffffff;
            margin-top:70px;
            @media screen and (max-width:767px){
              font-size: 32px !important;
              margin-top: 12px !important;
            }
        }
        p{
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.18px;
            text-align: left;
            color: #ffffff;
        }
        img{
            max-width:100%;
        }
    }
}
.shapeData{
    width:100%;
    display:inline-block;
    margin:50px 0px;
    img{
        max-width:100%;
        opacity: 0.3;
    }
    iframe{
        width:100%;
        height:450px;
        border:none;
    }
}
.box-data{
    width:100%;
    display:inline-block;
    padding-left:100px;
    box-sizing: border-box;
    margin-bottom:100px;
    position: relative !important;
    z-index: 1 !important;
    pointer-events: none !important;
    background: none !important;
    @media screen and (max-width:768px) {
        padding:0px !important;
    }
    .inner-box{
        position: relative;
        object-fit: contain;
        box-shadow: 0 0 22.5px 2.5px rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        width:100%;
        box-sizing: border-box;
        padding:50px 50px 50px 0px;
        box-sizing:border-box;
        border-radius: 20px 20px 20px 20px;
        border-bottom: 5px solid #c19d00;   
        &::after{
            content: "";
            position: absolute;
            right: 0px;
            top: 0;
            background:url(../images/circle.png);
            background-repeat:no-repeat;
            background-position: right top;
            background-repeat: no-repeat;
            background-size: contain;
            height:100px;
            width:100px;
        }
        @media screen and (max-width:768px) {
            padding:50px 0px 50px 0px !important;
        }
        .inner-info{
            object-fit: contain;
            box-shadow: 0 0 22.5px 2.5px rgba(0, 0, 0, 0.1);
            background-color: #c19d00;
            padding:30px 20px;
            margin-left: -97px;
            margin-right:130px;
            min-height:478px;
            @media screen and (max-width:768px) {
                margin-right:0 !important;
                margin-left:0 !important;
            }
            h4{
                color:#fff;
                font-size: 24px;
                font-weight: normal;
                font-stretch: condensed;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #ffffff;
                font-family: $poppins;
            }
            p{
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #ffffff;
                margin:30px 0px 30px;
            }
            .contact-info{
                width:100%;
                display:inline-block;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.57;
                letter-spacing: normal;
                text-align: left;
                color: #ffffff;
                margin:0px;
                img{
                    margin-right: 10px;
                    vertical-align: middle;
                    max-width: 18px;
                }
            }
        }
        .get-in-touch{
            width:100%;
            display:inline-block;
            @media screen and (max-width:768px) {
                padding:20px;
                box-sizing: border-box;
            }
            h5{
                font-size: 24px;
                font-weight: normal;
                font-stretch: condensed;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #c19d00;
                font-family: $poppins;
            }
            p{
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #868e96;
            }
            textarea{
                min-height:156px;
                resize: none;
                width:100%;
            }
            button{
                background:$primary-color;
                border-color:$primary-color;
                border-radius:25px;
                padding: .4rem 1.5rem;
                &:hover{
                    background: #c19d00;
                    border-color: #c19d00;
                    color: #fff;
                }
            }
        }
        .col-md-6{
            @media screen and (max-width:768px) {
                width:100% !important;
            }  
        }
    }
}
.maginn-data{
    margin-bottom:0px !important;
}
.margin-top{
    margin-top:5px !important;
}
.thanksYou{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.39);
    z-index: 9999;
}
.wrapper-2{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 10px #0000004D;
    border-radius: 16px;
    opacity: 1;
    text-align:center !important;
    padding:40px 20px 20px;
    width:500px;
    @media screen and (max-width:768px) {
        width:90% !important;
    }
  }
  .wrapper-2 h1{
    font-family: 'Kaushan Script', cursive;
    font-size:2em;
    letter-spacing:3px;
    color:#5892FF ;
    margin:0;
    margin-bottom:20px;
  }
  .wrapper-2 p{
    margin: 0;
    font-size: 1.3em !important;
    color: #aaa;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing: 1px !important;
    text-align: center !important;
  }
  .go-home{
    color:#fff;
    background:#5892FF;
    border:none;
    padding:10px 50px;
    margin:30px 0;
    border-radius:30px;
    text-transform:capitalize;
    box-shadow: 0 10px 16px 1px rgba(174, 199, 251, 1);
  }
  .outerMapInfo{
    width:100%;
    display:inline-block;
  }
  .outerMapInfo h3{
    font-size:40px;
    line-height: 1.25em;
    text-align:center;
    color:#14193f;
    margin:0px;
  }
  .elementor-widget-container{
    width:100%;
    display:inline-block;
    text-align:center;
    margin:20px 0px;
  }
  .elementor-widget-container p{
    font-size: 18px;
    font-weight: 500;
    color:#000637;
  }
  .elementor-widget-container p b{
    color:#c19d00;
  }
  .images_wrap{
    width:100%;
    display:inline-block;
    margin:30px 0px;
    position: relative;                                                                                                           
    background-image: url(../images/map-lt-gray.webp);
    background-size: contain;
		background-repeat: no-repeat;
    min-height:646px;
    @media screen and (max-width:768px) {
      min-height:inherit;
      background:none;
    }
  }
  .images_wrap img{
    opacity:inherit !important;
    max-width: inherit !important;
  }
  .pins_image_hover{
    top: 300px;
    position: absolute;
    right: 400px;
  }
  .pins_image_hover img{
    cursor: pointer;
  }
  .GetInTochInfo{
    position: absolute;
    background: #fff;
    width: 400px;
    padding: 20px;
    box-shadow: 0px 0px 10px #ccc;
    border-radius: 7px;
    display:none;
  }
  .getDirections{
    width:100%;
    display:inline-block;
    margin:10px 0px;
  }
  .getDirections a{
    text-decoration:none;
  }
  .pins_image_hover:hover .GetInTochInfo{
    display:block;
  }
  .GetInTochInfo p{
    margin:5px 0px;
  }
  .ukInfo{
    top: 188px;
    position: absolute;
    right: 695px;
    cursor: pointer;
  }
  .mexico{
    top: 309px;
    position: absolute;
    left: 231px;
    cursor: pointer;
  }
  .usa{
    top: 262px;
    position: absolute;
    left: 186px;
    cursor: pointer;
  }
  @media screen and (max-width:768px) {
    .ls-is-cached.lazyloaded{
        display:none !important;
    }
    .pins_image_hover{
      position:initial !important;
    }
    .GetInTochInfo{
        display:block !important;
        width:100% !important;
        position:inherit;
    }
    .outerMapInfo h3{
        font-size:25px !important;
    }
    .images_wrap{
        margin:0px;
    }
  }