$primary-color: #c19d00;
$white:#fff;
$black:#000;
$light-black:#333;
$red:#f44336;

$poppins: 'Poppins', sans-serif;
.featurette{
	margin-bottom:4rem;
	img{
		max-width:100%;
	}
	h6{
		  object-fit: contain;
		  font-family: $poppins;
		  font-size: 18px;
		  font-weight: 600;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: 1.33;
		  letter-spacing: 0.18px;
		  text-align: left;
			color: #c19d00;
			text-transform:uppercase;
		}
        h3{
            margin:10px 0px 30px;
			object-fit: contain;
		  font-family:$poppins;
		  font-size: 40px;
		  font-weight: normal;
		  font-stretch: condensed;
		  font-style: normal;
		  line-height: normal;
		  letter-spacing: normal;
		  text-align: left;
		  color: #c19d00;
        }
        h5{
            object-fit: contain;
            font-family: $poppins;
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.18px;
            text-align: left;
              color:$primary-color;
        }
}
.banner-info.bgdataUpdate{
	background-color:#c19d00;
	height:auto;
	margin-bottom:30px;
}
.outerDataInfoJob{
	width:100%;
	display:inline-block;
	margin-bottom:40px;
	margin-top:40px;
}
.bg-data{
    position: relative;
    padding-bottom:4rem;
    &:after{
        content:"";
        position:absolute;
        right:0px;
        bottom:0;
        background:url(../images/bg_data.webp);
        background-position:right bottom;
        background-position: right bottom;
        background-repeat: no-repeat;
        width:330px;
        height: 100%;
        background-size: contain;
		@media screen and (max-width:768px) {
			display:none;
		}
    }
}
.outerBgDataHr{
	width: 100%;
    display: inline-block;
    padding:60px 0px 60px;
    background: linear-gradient(0deg, #d9d9d9 0%, #c19d00 100%);
    margin: 0px 0px 100px;
}
.cursor{
	cursor: pointer;
}
.thanksYou{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.39);
    z-index: 9999;
}
.wrapper-2{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 10px #0000004D;
    border-radius: 16px;
    opacity: 1;
    text-align:center !important;
    padding:40px 20px 20px;
    width:500px;
    @media screen and (max-width:768px) {
        width:90% !important;
    }
  }
  .wrapper-2 h1{
    font-family: 'Kaushan Script', cursive;
    font-size:2em;
    letter-spacing:3px;
    color:#5892FF ;
    margin:0;
    margin-bottom:20px;
  }
  .wrapper-2 p{
    margin: 0;
    font-size: 1.3em !important;
    color: #aaa;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing: 1px !important;
    text-align: center !important;
  }
  .go-home{
    color:#fff;
    background:#5892FF;
    border:none;
    padding:10px 50px;
    margin:30px 0;
    border-radius:30px;
    text-transform:capitalize;
    box-shadow: 0 10px 16px 1px rgba(174, 199, 251, 1);
  }
  
  .ondemand.new{
	position: absolute;
	top:100px;
	font-size: 24px;
	font-weight: 400;
	color: #fff;
	max-width: 93px;
	background: #c19d00;
	padding:20px 10px;
	text-align: center;
	left: -26px;
	border-radius: 6px;
	@media screen and (max-width:768px) {
		top: -38px;
		left: 4px;
	}
	h4{
	  font-size: 20px;
	  margin: 0 0 6px;
	}
	p{
	  margin: 0;
	  font-size: 12px;
	  color:#fff;
	}
  }
  .newdataContent{
	@media screen and (max-width:767px) {
		margin-top:30px;
	}

	p{
		font-weight: normal;
	}
  }
  .testiMonialEmpl{
	width:100%;
	display:inline-block;
	margin-top:100px;
	@media screen and (max-width:767px) {
		margin-top:0px;
	}
  }
  .bg-data.hDataHeading{
	.featurette{
		h1{
			margin: 10px 0px 0px;
			font-size:40px;
		}
	}
  }
  .get-in-touchJob{
	background-color: #cccccc57;
    padding: 20px;
    width: 100%;
    display: inline-block;
    border-radius: 10px;
    box-sizing: border-box;
    pointer-events: auto;
  }

h6{
  color: whitesmoke;
}
ul{
  color: white;
}