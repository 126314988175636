.main{
    width:100%;
    position: relative;
    z-index: 99;
}
//.animationData{
//    position: fixed;
//    top: 0;
//    left: 0;
//    right: 0;
//    bottom: 0;
//    width: 100% !important;
//    height: 100% !important;
//    opacity: 1;
//    canvas {
//        top: 0;
//        left: 0;
//        width: 100%;
//        height: 100%;
//    }
//}