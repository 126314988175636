.outerProcess{
  display: flex;
  justify-content: center;
  .timeline {
    margin: 20px auto;
    padding: 20px;
  }

  /* Card container */
  .cardNew {
    position: relative;
    max-width: 400px;
  }

  /* setting padding based on even or odd */
  .cardNew:nth-child(odd) {
    padding: 30px 0 30px 30px;
  }
  .cardNew:nth-child(even) {
    padding: 30px 30px 30px 0;
  }
  /* Global ::before */
  .cardNew::before {
    content: "";
    position: absolute;
    width: 50%;
    border: solid #c19d00;
  }

  /* Setting the border of top, bottom, left */
  .cardNew:nth-child(odd)::before {
    left: 0;
    top: -4.5px;
    bottom: -4.5px;
    border-width: 5px 0 5px 5px;
    border-radius: 50px 0 0 50px;
  }

  /* Setting the top and bottom to "-5px" because earlier it was out of a pixel in mobile devices */
  @media only screen and (max-width: 400px) {
    .cardNew:nth-child(odd)::before {
      top: -5px;
      bottom: -5px;
    }
  }

  /* Setting the border of top, bottom, right */
  .cardNew:nth-child(even)::before {
    right: 0;
    top: 0;
    bottom: 0;
    border-width: 5px 5px 5px 0;
    border-radius: 0 50px 50px 0;
  }

  /* Removing the border if it is the first card */
  .cardNew:first-child::before {
    border-top: 0;
    border-top-left-radius: 0;
  }

  /* Removing the border if it is the last card  and it's odd */
  .cardNew:last-child:nth-child(odd)::before {
    border-bottom: 0;
    border-bottom-left-radius: 0;
  }

  /* Removing the border if it is the last card  and it's even */
  .cardNew:last-child:nth-child(even)::before {
    border-bottom: 0;
    border-bottom-right-radius: 0;
  }

  /* Information about the timeline */
  .info {
    display: flex;
    flex-direction: column;
    background: #fff;
    color: gray;
    border-radius: 10px;
    padding: 10px;
    font-size:16px;
  }

  /* Title of the card */
  .title {
    color: #c19d00;
    position: relative;
  }

  /* text right if the card is even  */
  .cardNew:nth-child(even) > .info > .title {
    text-align: right;
  }


  /* Timeline dot  */
  .title::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background: white;
    border-radius: 999px;
    border: 3px solid #c19d00;

  }
  /* setting dot to the left if the card is odd */
  .cardNew:nth-child(odd) > .info > .title::before {
    left: -45px;
    animation: moveAroundOdd 5s linear infinite;

  }



  /* setting dot to the right if the card is odd */
  .cardNew:nth-child(even) > .info > .title::before {
    right: -45px;
    animation: moveAroundEven 5s linear infinite;

  }

  .cardNew:last-child:nth-child(odd) > .info > .title::before {
    animation: moveAroundOdd 5s linear infinite;

  }
  .cardNew:last-child:nth-child(even) > .info > .title::before {
    animation: moveAroundLastEven 2s linear infinite;
  }
  .cardNew:first-child:nth-child(odd) > .info > .title::before {
    animation: moveAroundOdd 5s linear infinite;
  }


  @keyframes moveAroundEven {
    0% {
      top: 45px;
      right: -45px;
    }
    15% {
      top: 70px;
      right: -45px;
    }
    30% {
      top: 90px;
      right: -45px;
    }
    40% {
      top: 120px;
      right: -25px;
    }
    50% {
      top: 140px;
      right: -15px;
    }
    70% {
      top: 140px;
      right: 0;
    }
    85% {
      top: 140px;
      right: 150px;
    }
    100% {
      top: 140px;
      right: 300px;
    }
  }
  @keyframes moveAroundLastEven {
    0% {
      top: 45px;
      right: -45px;
    }
    35% {
      top: 70px;
      right: -45px;
    }
    50% {
      top: 90px;
      right: -45px;
    }
    70% {
      top: 110px;
      right: -45px;
    }
    100% {
      top: 130px;
      right: -45px;
    }
  }




  @keyframes moveAroundOdd {
    0% {
      top: 45px;
      left: -45px;
    }
    15% {
      top: 70px;
      left: -45px;
    }
    30% {
      top: 90px;
      left: -45px;
    }
    40% {
      top: 120px;
      left: -25px;
    }
    50% {
      top: 140px;
      left: -15px;
    }
    70% {
      top: 140px;
      left: 0;
    }
    85% {
      top: 140px;
      left: 150px;
    }
    100% {
      top: 140px;
      left: 300px;
    }
  }


// To light up the card on hover
  .cardNew{
    pointer-events: auto;
  }
  .cardNew .info {
    transition: transform 0.3s, box-shadow 0.3s;
  }

  .cardNew .info:hover {
    transform: scale(1.07);
    box-shadow: 4px 4px 8px rgba(64, 114, 212, 0.8);
  }
}