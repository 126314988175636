$primary-color: #c19d00;
$white:#fff;
$black:#000;
$light-black:#333;
$red:#f44336;

$poppins: 'Poppins', sans-serif;
@media screen and (max-width:767px) {
	.navbar-light .navbar-toggler{
		background:#fff;
	}
}
.outerMainOuter{
	transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 30px 0px 60px 0px;
	position: relative;
	height:600px;
	display: flex;
    align-items: center;
    justify-content: center;
	background:#000;
	position: relative;
	z-index:9;
    pointer-events: none;
	@media screen and (max-width:767px) {
		background:none !important;
		height: auto;
        margin-top: 64px;
		padding-bottom:20px;
	}
	.contentCenter{
		position:absolute;
		top:36%;
		text-align:center;
		max-width:800px;
		pointer-events: none;
		@media screen and (max-width:767px) {
			position: initial;
		}
		h3{
			color: #FFFFFF;
			font-size: 72px;
			font-weight: 500;
			text-transform: uppercase;
			line-height: 80px;
			letter-spacing: 2.2px;
			@media screen and (max-width:767px) {
				font-size:30px;
				line-height:45px;
			}
		}
		p{
			color: #FFFFFF;
			font-size: 20px;
			font-weight: 400;
			text-transform: uppercase;
			letter-spacing: 2.2px;
			@media screen and (max-width:767px) {
				font-size:16px;
			}
		}
	}
}
.elementor-background-video-embed{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
}
.videoOuterInfo iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
	width: 1349px;
    height: 760px;
	pointer-events: none;
}
.videoOuterInfo{
	height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
    z-index: 0;
    direction: ltr;
	transition: opacity 1s;
    pointer-events: none;
	@media screen and (max-width:767px) {
		display:none !important;
	}
}
.owl-carousel{
	margin-bottom:40px;
	background:$primary-color;
	background:#000;
	margin-top:4rem;
	pointer-events: none;
	.owl-item{
		.item{
			min-height:430px;
			padding:0px 70px;
			box-sizing: border-box;
			@media only screen and (max-width:767px) {
				min-height:300px;
				padding:0px 10px;
			}
		}
	}
	.carousel-indicators li{
		width:10px;
		height:10px;
		border-radius:100%;
	}
	.carousel-caption{
		position:inherit;
		padding:0px;
		.bg-image {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}
		h2{
		  object-fit: contain;
		  font-family:$poppins;
		  margin:90px 0px 50px;
		object-fit: contain;
		font-size: 50px;
		font-weight:600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		text-align: left;
		color: #fff !important;
		}
		p{
		  object-fit: contain;
		  font-family: $poppins;
		  object-fit: contain;
			font-size: 30px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.2;
			letter-spacing: normal;
			text-align: left;
			color: #fff;
		  margin:40px 0px 34px;
		}
		.btn-primary{
			  padding: 13px 35px 12px;
			  object-fit: contain;
			  border: solid 1px $primary-color;
			  background-color: $white;
              border-radius:30px;
			  font-family: $poppins;
			  font-size: 16px;
			  font-weight: 600;
			  font-stretch: normal;
			  font-style: normal;
			  line-height: normal;
			  letter-spacing: normal;
			  text-align: left;
			  color: #c19d00;
			  text-decoration:none;
				&:hover{
				 background: #007bff;
				border-color: #007bff;
				color:#fff;
				}
		}
	}
	.owl-dots{
	position: absolute;
	right: 0;
	bottom:10px;
	left: 0;
	z-index: 15;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 0;
	margin-right: 15%;
	margin-left: 15%;
	list-style: none;
	margin:0px;
	}
	.owl-item{
		img{
			display:initial !important;
			width:auto !important;
			max-width:100% !important;
		}
	}
}
@media only screen and (max-width:767px) {
	.owl-carousel{
		text-align: center !important;
		margin-top:77px;
		.carousel-caption {
		h2{
			font-size: 30px;
			margin:48px 0px 25px;
			text-align:left;
			min-height: max-content;
			line-height: initial;
			height: auto;
		}
		p{
			margin: 14px 0px;
			text-align: center;
			font-size: 21px;
			line-height: 32px;
		}
	}
	}
	
}
.main.owl-theme .owl-nav.disabled + .owl-dots{
	margin-top: 0;
    position: absolute;
    bottom: 34px;
}
.innovation{
	width:100%;
	display:inline-block;
	@media only screen and (max-width:767px) {
		display:none;
	}
}