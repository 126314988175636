$primary-color: #c19d00;
$white:#fff;
$black:#000;
$light-black:#333;
$red:#f44336;

$poppins: 'Poppins', sans-serif;
.featurette{
	margin-bottom:4rem;
	img{
		max-width:100%;
	}
	h6{
		  object-fit: contain;
		  font-family: $poppins;
		  font-size: 18px;
		  font-weight: 600;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: 1.33;
		  letter-spacing: 0.18px;
		  text-align: left;
			color: #c19d00;
			text-transform:uppercase;
		}
		h1{
			margin:10px 0px 30px;
			object-fit: contain;
		  font-family:$poppins;
		  font-size: 50px;
		  font-weight: normal;
		  font-stretch: condensed;
		  font-style: normal;
		  line-height: normal;
		  letter-spacing: normal;
		  text-align: left;
		  color: #c19d00;
		}
		p{
			 object-fit: contain;
			 font-family: $poppins;
			  font-size: 16px;
			  font-weight: 400;
			  font-stretch: normal;
			  font-style: normal;
			  line-height: 1.33;
			  letter-spacing: 0.18px;
			  text-align: left;
			  color: #000;
			  margin:0px 0px 20px;
		}
}
.director a{
	color: #c19d00;
    text-decoration: none;
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
}
.directorMsg{
	width: 100%;
    display: inline-block;
    background:#d9d9d973;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
	h3{
		margin:20px 0px 0px !important;
		font-size:25px !important;
	}
}
.dataInfoCnt{
	p{
		text-align:justify !important;
	}
}