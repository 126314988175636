$primary-color: #c19d00;
$white: #fff;
$black: #000;
$light-black: #333;
$red: #f44336;
.header-top {
  width: 100%;
  display: inline-block;
  box-shadow: 0 1px 6px 0 #20212447;
  background-color: #00344652;
  .bg-light {
    background: #2323234f !important;
    padding: 0px 10px;
    width: 100%;
    box-sizing: border-box;
    @media (max-width: 767px) {
      padding: 10px 15px;
    }

    .navbar-brand {
      margin-right: 2rem;

      img {
        max-width: 130px;
      }
    }
  }

  .navbar-light .navbar-nav .nav-link {
    color: $white;
    // border-bottom:5px solid transparent;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 0;
      height: 5px;
      background: $primary-color;
      transition: width .3s;
      position: absolute;
      bottom: -5px;
      margin: 0;
      padding: 0;
      border: none;
    }
  }

  // .navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link{
  // 	color:$white;
  // 	background-color:$primary-color;
  // }
  @media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: .0rem;
      padding-left: .0rem;
      margin-left: 0px;
      font-weight: 600;
      padding: 20px 25px;
      font-size: 16px;
    }
    .navbar .nav-item.dropdown:hover .dropdown-menu {
      display: block;
    }

  }

  .navbar .nav-item.dropdown {
    &:hover {
      color: $white;
      background: $primary-color;

      a {
        color: $white;
      }

      .nav-link {
        color: $white;
      }
    }
  }

  .btn-primary {
    background-color: $primary-color;
    border-color: $primary-color;
    border-radius: 25px;
    padding: .4rem 1.5rem;

    &:hover {
      background: #c19d00;
      border-color: #c19d00;
      color: #fff;
    }
  }

  .secondary {
    background: #c19d00;
    border-color: #c19d00;

    &:hover {
      background: #165496;
      border-color: #165496;
      color: #fff;
    }
  }

  .dropdown-item {
    border-bottom: 1px solid #4f5d6e;
    padding: 10px 20px 10px;

    &:focus {
      background: $primary-color;
      color: $white;
      outline: none;
    }

    &:hover {
      background: #00000078;
      color: $white;
      outline: none;
    }
  }

  .lowercase {
    text-transform: initial;
  }

  .dropdown-menu {
    left: 0px;
    margin-top: -2px;
    background-color: $primary-color;
    padding: 0px;
    box-shadow: 0px 0px 2px #ccc;
    -webkit-transition: opacity .5s ease .1s;
    -o-transition: opacity .5s ease .1s;
    transition: opacity .5s ease .1s;

    .dropdown-item {
      color: $white;
    }
  }
}

@media only screen and (max-width: 767px) {
  .header-top {
    .bg-light {
      .navbar-brand {
        img {
          max-width: 115px;
        }
      }
    }

    .btn-primary {
      margin-bottom: 10px;
    }
  }

}

.nav-item.dropdown:hover .dropdown-menu {
  display: block !important;
}

.dropdown-menu {
  &.newMenuUpdate {
    left: 0;
    position: absolute;
    width: 100%;
    border-radius: 0px;
    padding: 20px;
    box-sizing: border-box;

    .newMenuHeaderBox {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .leftBoxDataMenu {
        width: 16%;

        h3 {
          background-color: #c19d00;
          color: #fff;
          padding: 10px;
          width: 100%;
          font-size: 25px;
          margin: 0px 0px 10px;
        }

        ul {
          padding-left: 0px;

          li {
            list-style: none;
          }
        }
      }
    }
  }
}